const connections: [[string, string], string[], string[]] = [
  [
    'entrypoint-398705a7-5bbe-4f53-964a-7fb6b91dd000',
    'template-6655e229-8af9-4046-ac3b-b99987b8ef47',
  ],
  [
    'template-6655e229-8af9-4046-ac3b-b99987b8ef47',
    'group-42e2f7fc-dc30-4208-94a3-2f20505ec7ab',
  ],
  [
    'group-42e2f7fc-dc30-4208-94a3-2f20505ec7ab',
    'template-b9e69a4f-b3ad-4a77-a023-4cf083e31ca0',
  ],
];

export default connections;
