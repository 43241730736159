import axiosHelper from '../helper/axiosHelper';
import * as env from '../../env';

const serverUrl = env.httpProtocol.concat(
  env.serverHost,
  ':',
  env.serverPort,
  '/pipeline',
);

const paramHeaders = {
  headers: {
    Accept: 'application/json',
  },
  withCredentials: true,
};

export const listWorkflowsAPI = async () => {
  const url = serverUrl.concat('/list-workflows');
  return await axiosHelper.axiosGet(url, paramHeaders);
};

export const getWorkflowAPI = async (name: string) => {
  const url = serverUrl.concat('/get-workflow/', name);
  return await axiosHelper.axiosGet(url, paramHeaders);
};

export const createWorkflowAPI = async (body: any) => {
  const url = serverUrl.concat('/create-workflow');
  return await axiosHelper.axiosPost(url, body, paramHeaders);
};

export const getWorkflowLogsAPI = async (name: string) => {
  const url = serverUrl.concat('/get-workflow-logs/', name);
  return await axiosHelper.axiosGet(url, paramHeaders);
};

export const deleteWorkflowAPI = async (name: string) => {
  const url = serverUrl.concat('/delete-workflow/', name);
  return await axiosHelper.axiosDelete(url, paramHeaders);
};

export const listWorkflowTemplatesAPI = async () => {
  const url = serverUrl.concat('/list-workflow-templates');
  return await axiosHelper.axiosGet(url, paramHeaders);
};

export const getWorkflowTemplateAPI = async (name: string) => {
  const url = serverUrl.concat('/getWorkflow-template/', name);
  return await axiosHelper.axiosGet(url, paramHeaders);
};

export const createWorkflowTemplateAPI = async (body: any) => {
  const url = serverUrl.concat('/create-workflow-template');
  return await axiosHelper.axiosPost(url, body, paramHeaders);
};

export const deleteWorkflowTemplateAPI = async (name: string) => {
  const url = serverUrl.concat('/delete-workflow-template/', name);
  return await axiosHelper.axiosDelete(url, paramHeaders);
};